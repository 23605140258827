@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url('../assets/fonts/Ubuntu-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('../assets/fonts/sohne-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('../assets/fonts/sohne-bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}
