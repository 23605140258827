@import-normalize;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

h1 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: var(--font-size-display);
  letter-spacing: 0.5px;
}

h2 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: var(--font-size-large);
  letter-spacing: 0.5px;
}

h3 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: var(--font-size-medium);
  letter-spacing: 0.5px;
}

h4 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: var(--font-size-small);
  letter-spacing: 0.5px;
}

p {
  margin: 0;
  line-height: 28px;
  font-size: var(--font-size-small);
}

@media screen and (max-width: 767px) {
  p {
    line-height: 24px;
  }
}
