html {
  /*colors*/
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light-grey: #f5f5f5;
  --color-medium-grey: #bbb9b9;
  --color-dark-grey: #4e4e4e;
  --color-purple: #53334e;
  --color-yellow: #ecc35a;
  --color-orange: #ca6e39;
  --color-red: #ad6677;
  --color-green: #0b575c;
  --color-dark-green: #113133;
  --color-blue: #8bb3d9;

  /*fonts*/
  font-size: 16px;
  font-family: 'Sohne';
  --font-primary: 'Sohne';
  --font-size-tiny: 0.5rem;
  --font-size-small: 1rem;
  --font-size-medium: 1.25rem;
  --font-size-large: 1.75rem;
  --font-size-display: 2.25rem;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: bold;

  /*component sizes*/
  --grid-row-gap: 1rem;
  --grid-col-gap: 1rem;
  --border-radius: 5px;
  --sidebar-width: 16em;
  --input-height: 38px;
  --button-height: 3rem;
}
